
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import EditorWidget from "@/components/widgets/TinyMCE.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";
import { useRouter } from "vue-router";

// interface UnderwriterData {
//   underwriter: string;
//   terms_and_conditions: {
//     package: string;
//     terms: string;
//   }[];
//   rates: {
//     id: string;
//     value: string;
//   }[];
//   loadings_and_discounts: {
//     id: string;
//     value: string;
//   }[];
//   additional_loadings_and_discounts: {
//     name: string;
//     type: string;
//     rate_type: string;
//     value: number;
//     execution_order: number;
//     target: string;
//   }[];
//   applicable_packages: { id: string }[];
// }

export default defineComponent({
  name: "edit-underwriter-package-rate",
  components: { Field, ErrorMessage, EditorWidget, GoBackButton },
  props: {
    publicId: String,
    underwriterId: String,
  },
  data() {
    return {
      // publicId: this.$route.params.publicId,
      redirect: "admin-underwriters-create-package",
      createRoute: variables.SUPERADMIN_UNDERWRITER_ROUTE,
      businessClass: "",
      loadings: [
        {
          name: "",
          type: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          rate_type: "",
          value: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          execution_order: 0,
          target: "",
        },
      ],
      featureId: "",
      unchecked: "",
    };
  },
  setup(props) {
    const {
      capitalize,
      getDynamicModelName,
      isObjectEmpty,
      isEmptyArray,
    } = ResusableFunctions();
    const router = useRouter();
    const submitButton1 = ref<HTMLElement | null>(null);
    const displayFields = ref(false);
    const businessClass = ref("");
    const underwriter = ref("");

    const terms = ref([
      {
        businessClass: "",
        businessType: "",
        name: "",
        fqcn: "",
        publicId: "",
      },
    ]);

    const actualTerms = ref([
      {
        content: { id: "", text: "" },
        package: {
          name: "",
          businessClass: "",
          businessType: "",
          fqcn: "",
          publicId: "",
        },
        underwriter: "",
        terms: "",
      },
    ]);

    const rates = ref([
      {
        name: "",
        publicId: "",
        value: "",
        unit: "",
      },
    ]);

    const defaultLoadings = ref([
      {
        name: "",
        id: "",
        loadingsAndDiscounts: {
          id: "",
          name: "",
          rateType: "",
          type: "",
          value: "",
          unit: "",
        },
      },
    ]);

    const validationSchema = Yup.object().shape({
      // eslint-disable-next-line @typescript-eslint/camelcase
      terms_and_conditions: Yup.array().of(
        Yup.object().shape({
          package: Yup.string().required(),
          terms: Yup.string().required(),
        })
      ),
      rates: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      loadings_and_discounts: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      // additional_loadings_and_discounts: Yup.array().of(
      //   Yup.object().shape({
      //     name: Yup.string().required(),
      //     type: Yup.string().required(),
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     rate_type: Yup.string().required(),
      //     value: Yup.number().required(),
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     execution_order: Yup.number().required(),
      //     target: Yup.string().required(),
      //   })
      // ),
      // // eslint-disable-next-line @typescript-eslint/camelcase
      // applicable_packages: Yup.array().of(
      //   Yup.object().shape({
      //     id: Yup.string().required(),
      //   })
      // ),
    });

    const handleContentUpdate = (id, newContent) => {
      const editorItem = actualTerms.value.find(
        (item) => item.package.publicId === id
      );
      if (editorItem) {
        // console.log(id, newContent);
        editorItem.content = newContent;
      }
    };

    const submit = () => {
      const form = document.forms[0];
      const formData = new FormData(form);
      formData.append("underwriter", underwriter.value);
      formData.append("business_class", businessClass.value);

      actualTerms.value.forEach((item, index) => {
        formData.append(
          getDynamicModelName(index, "terms_and_conditions", "terms"),
          item.content.text
        );
      });

      // const additionalLoadingsField = "additional_loadings_and_discounts";
      // const fields = [
      //   "name",
      //   "value",
      //   "type",
      //   "rate_type",
      //   "execution_order",
      //   "target",
      // ];

      // for (const field of fields) {
      //   const key = `${additionalLoadingsField}[0][${field}]`;

      //   if (formData.has(key)) {
      //     const value = formData.get(key);

      //     // Check if the value is empty (for strings) or 0 (for numbers)
      //     if (
      //       (typeof value === "string" && value.trim() === "") ||
      //       (typeof value === "number" && value === 0)
      //     ) {
      //       formData.delete(key);
      //     }
      //   }
      // }

      // return new Response(formData).text().then(console.log);

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.SUPERADMIN_UNDERWRITERS_RATE_ROUTE}/${props.underwriterId}`,
              formData
            )
              .then((response) => {
                variables.toastAlert(
                  "Rates, terms & conditions was updated successfully.",
                  "success"
                );
                router.push({
                  name: "admin-underwriters-users-details",
                  params: { publicId: underwriter.value },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const getBusinessClass = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.UNDERWRITERS_RATE_ROUTE}/${props.underwriterId}/${props.publicId}`
        )
          .then(({ data }) => {
            if (!isObjectEmpty(data.data)) {
              defaultLoadings.value = data.data.packages;
              // terms.value = data.data.loadingsAndDiscounts[0].appliedTo;
              rates.value = data.data.rates;
              actualTerms.value = data.data.termsAndConditions;
              underwriter.value = data.data.underwriterPublicId;
              businessClass.value = data.data.businessClassPublicId;
              displayFields.value = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Underwriters", ["Edit Package Rates"]);
      getBusinessClass();
    });

    return {
      submitButton1,
      defaultLoadings,
      rates,
      // terms,
      getBusinessClass,
      capitalize,
      isEmptyArray,
      submit,
      validationSchema,
      displayFields,
      getDynamicModelName,
      actualTerms,
      handleContentUpdate,
    };
  },
  created() {
    //Set page title
    document.title =
      "Edit Underwriter Package Rates | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addLoadingsField() {
      this.loadings.push({
        name: "",
        type: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        rate_type: "",
        value: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        execution_order: 0,
        target: "",
      });
    },
    removeLoadingsField(index, length) {
      // Remove loadings dynamic field
      $(".remove-loadings-" + index).remove();
    },
  },
});
