
import { defineComponent, toRefs, ref, watch } from "vue";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "tinymce-editor",
  components: { Editor },
  props: {
    widgetClasses: String,
    header: String,
    value: String,
    id: String,
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);
    const content = ref(value.value || "");
    const initialContent = ref(value.value || "");
    const editorKey = process.env.VUE_APP_TINY_MCE;

    const editorConfig = {
      height: 350,
      // eslint-disable-next-line @typescript-eslint/camelcase
      max_height: 350,
      // menubar: true,
      // selector: "textarea",
      schema: "html5",
      plugins: [
        `advlist autolink autosave autoresize link image lists charmap hr`,
        `searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking`,
        `print preview anchor insertdatetime media`,
        `paste code help wordcount table`,
        `table template importcss textpattern`,
      ],
      toolbar: `undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | \
          bullist numlist outdent indent | help | subscript superscript | charmap | visualchars visualblocks nonbreaking | template | helloworld`,
      themes: `modern`,
    };

    const updateContent = (event: any) => {
      const text = event.target.activeElement.innerHTML;
      emit("contentUpdated", { index: props.id, text });
    };

    // Watch for changes in the value prop and update the content accordingly
    watch(
      () => props.value,
      (newValue) => {
        content.value = newValue || "";
      }
    );

    return {
      editorKey,
      editorConfig,
      content,
      updateContent,
      initialContent,
    };
  },
});
